import React from 'react'
import cause1 from '../../images/event/cause1.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const CauseSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
               
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={cause1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-clipboard"></i> GOAL: $10,000</li>
                                        <li><i className="ti-receipt"></i> RAISED: $500</li>
                                    </ul>
                                    <h2>Elderly Persons.</h2>
                                    <p>The World Health Organisation (WHO) recommends 50 years and above instead of 60 to define older people in sub-Saharan Africa. This is primarily because of low life expectancy in the region. In addition, people aged 50 and older have health and functional limitations akin to those experienced by people 60 or above in developed countries.
There are about 46 million older people (5% of the population) in sub Saharan Africa, and this is expected to increase four-fold by 2050. In Uganda, the number of older people has increased from 1.1 million in 2002, to 1.3 million in 2010 and projected to 5.5 million by 2050.
.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                             <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default CauseSection;