import React from 'react'
import cause1 from '../../images/event/cause5.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const CauseSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
               
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={cause1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-clipboard"></i> GOAL: $15,000</li>
                                        <li><i className="ti-receipt"></i> RAISED: $1,000</li>
                                    </ul>
                                    <h2>Community Outreach.</h2>
                                    <p>In Uganda, the church has seen remarkable evangelistic efforts and numerical growth in the last decades, but however many churches have not emphasized the discipleship programs which has left many Christian spiritually stunted. The body of Christ in Uganda must be led into a growing relationship with God through the method that Jesus Christ established – true discipleship. Youths and other elderly people have a great potential to transform their community if they are well equipped with the word of God.
 Matthew 4:9 ‘And he saith unto them, Follow me, and I will make you fishers of men’, So through the discipleship training, the youths, elderly people and other ministers are equipped, taught the word of God, and empowered to becoming fishers of men and continue serving the Lord as well as the communities they live in as Christ commanded us to do.
</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                             <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default CauseSection;