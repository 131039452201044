import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo/logo.png'
import n1 from '../../images/instragram/1.jpg'
import n2 from '../../images/instragram/2.jpg'
import n3 from '../../images/instragram/3.jpg'
import n4 from '../../images/instragram/4.jpg'
import n5 from '../../images/instragram/5.jpg'
import n6 from '../../images/instragram/6.jpg'
import './style.css'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="tp-site-footer">
        <div className="tp-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="footer-logo widget-title">
                                <Link onClick={ClickHandler} to="index.html"><img src={Logo} alt="logo"/>PCF <span> Uganda</span></Link>
                            </div>
                            <p>Our mission is to follow our Lord Jesus Christ in working with the poor and oppressed to promote human transformation, seek justice, and bearing witness of the Kingdom of God.</p>
                            
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Quick Links</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="about/">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="case/">Our Causes</Link></li>
                                <li><Link onClick={ClickHandler} to="contact/">Contact Us</Link></li>
                                <li><Link onClick={ClickHandler} to="donate/">Donate</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-lg-offset-1 col-md-6 col-sm-12 col-12">
                        <div className="widget market-widget tp-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <p>To send us a message, please feel free to contact us on the address indicated below</p>
                            <div className="contact-ft">
                                <ul>
                                    <li><i className="fi flaticon-pin"></i>KAWEMPE AND NEBBI, UGANDA</li>
                                    <li><i className="fi flaticon-call"></i>+256 754990251</li>
                                    <li><i className="fi flaticon-envelope"></i>info@powerchildrenfoundationuganda.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Insights</h3>
                            </div>
                            <ul className="d-flex">
                                <li><Link onClick={ClickHandler} to="/"><img src={n1} alt=""/></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><img src={n2} alt=""/></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><img src={n3} alt=""/></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><img src={n4} alt=""/></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><img src={n5} alt=""/></Link></li>
                                <li><Link onClick={ClickHandler} to="/"><img src={n6} alt=""/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="tp-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright">&copy; 2022 Power Children Foundation Uganda | All rights reserved | A
                        <Link to={{ pathname: "http://loomhosting.net/" }} target="_blank"><span> LoomHosting </span></Link> Works</p>

                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;