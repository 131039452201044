import React from 'react'
import cause1 from '../../images/event/cause2.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const CauseSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
               
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={cause1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-clipboard"></i> GOAL: $2496</li>
                                        <li><i className="ti-receipt"></i> RAISED: $150</li>
                                    </ul>
                                    <h2>Education for Poor Children.</h2>
                                    <p>About 263 million children and youth are out of school, according to new data from the UNESCO Institute for Statistics (UIS). This is equivalent to a quarter of the population of Europe. The total includes 61 million children of primary school age, 60 million of lower secondary school age, and includes the first ever estimate of those of upper secondary school age at 142 million. 
Girls still more likely than boys to never go to school
Girls are more likely than boys never to set foot in a classroom, despite all the efforts and progress made over the past two decades. According to UIS data, 15 million girls of primary-school age will never get the chance to learn to read or write in primary school compared to about 10 million boys. Over half of these girls - 9 million - live in sub-Saharan Africa.
Poverty creates an additional barrier for girls. In Northern Africa and Western Asia, according to GEM Report analysis, among the poorest in the region, gaps are far wider: only 85 girls for every 100 boys of lower secondary school age attend school. Among those of upper secondary school age, only 77 of the poorest girls for every 100 of the poorest boys attend.
</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                             <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default CauseSection;