import React from 'react'
import {Link} from 'react-router-dom'
import causesimg from '../../images/cause/img-1.png'
import causesimg2 from '../../images/cause/img-2.jpg'
import causesimg3 from '../../images/cause/img-3.jpg'
import causesimg4 from '../../images/cause/img-4.jpg'
import causesimg5 from '../../images/cause/img-5.jpg'
import causesimg6 from '../../images/cause/img-6.jpg'
import './style.css'

const Causes = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="case-area section-padding">
            <div className="container">
                <div className="col-lg-6 offset-lg-3">
                    <div className="section-title section-title2 text-center">
                        <div className="thumb-text">
                            <span>CAUSES</span>
                        </div>
                        <h2>Our Latest Campaigns.</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/cause1" className="theme-btn">Read More<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $10,000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $500</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/">Elderly Persons</Link></h3>
                                <p>Elderly persons do desire support in terms of welfare and health care.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg2} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/cause2" className="theme-btn">Read More<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $2496</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $150</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/">Education for Poor Children</Link></h3>
                                <p>Provision of scholastic matrials and free education for the disadvantaged children. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg3} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/cause3" className="theme-btn">Read More<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $7000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $200</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/">Food And Home for Children</Link></h3>
                                <p>Orphans require shelter and feeding to sustain their lives among the previlaged</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg4} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/cause4" className="theme-btn">Read More<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $5000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $300</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/">Single Mothers</Link></h3>
                                <p>Single mothers require support to educate and sustain their children.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg5} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/cause5" className="theme-btn">Read More<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $15,000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $1,000</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/">Community Outreach</Link></h3>
                                <p>Community Outreach with the gospel of our Lord Jesus to Communities.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg6} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/cause6" className="theme-btn">Read More<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">GOAL : $20,000</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">RISED : $7,000</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/">Training and Skilling</Link></h3>
                                <p>Sustainable skill are trained to the young genrations to equip them</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Causes;