import React from 'react'
import cause1 from '../../images/event/cause3.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const CauseSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
               
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={cause1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-clipboard"></i> GOAL: $7,000</li>
                                        <li><i className="ti-receipt"></i> RAISED: $200</li>
                                    </ul>
                                    <h2>Food And Home for Children.</h2>
                                    <p>In Africa, hunger is increasing at an alarming rate. The COVID-19 pandemic, conflict, drought, economic woes, and extreme weather are reversing years of progress. As of 2019, 234 million sub-Saharan Africans were chronically undernourished, more than in any other region. In the whole of Africa, 250 million people were experiencing hunger, which is nearly 20% of the population.
Conditions are deteriorating across East Africa, where 7 million people are at risk of starvation and another 33.8 million face acute food insecurity. At least 12.8 million children are acutely malnourished in the region.
“It's heartbreaking that the lives of millions of children in East Africa are at risk due to a perfect storm of conflict, changing or unpredictable weather patterns, and the aftershocks of COVID-19 the long-term harm of malnutrition on children’s development hinders their ability to achieve their God-given potential.”
</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                             <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default CauseSection;