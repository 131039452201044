import React from 'react'
import {Link} from 'react-router-dom'
import abimg from '../../images/about.jpg'
import abimg2 from '../../images/about/1.png'
import abimg3 from '../../images/about/2.png'
import abimg4 from '../../images/shape/shape2.png'

const About2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12  grid col-12">
                        <div className="video-area">
                            <img src={abimg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 grid  col-md-12 col-12">
                        <div className="about-text">
                            <div className="section-title">
                                <div className="thumb-text">
                                    <span>ABOUT US</span>
                                </div>
                                <h2>Power Children Foundation<span> Uganda</span></h2>
                            </div>
                            <p>We are a Christians faith based organization whose mission is to follow our Lord and Savior Jesus Christ in working with the poor and oppressed to promote human transformation, seek justice, and bear witness to the good news of the Kingdom of God.</p>
                            <div className="ab-icon-area">
                                <div className="about-icon-wrap">
                                    <div className="about-icon-item">
                                        <div className="ab-icon">
                                            <img draggable="false" src={abimg2} alt="" />
                                        </div>
                                        <div className="ab-text">
                                        <h2><Link onClick={ClickHandler} to="/case-single">Save their Future.</Link></h2>
                                        </div>
                                    </div>
                                    <div className="about-icon-item">
                                        <div className="ab-icon ab-icon2">
                                            <img draggable="false" src={abimg3} alt="" />
                                        </div>
                                        <div className="ab-text">
                                        <h2><Link onClick={ClickHandler} to="/case-single">Empowering them with Skills.</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ab-shape">
                <img src={abimg4} alt="" />
            </div>
        </div>
    )
}

export default About2;