import React from 'react'
import cause1 from '../../images/event/cause4.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const CauseSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
               
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={cause1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-clipboard"></i> GOAL: $5,000</li>
                                        <li><i className="ti-receipt"></i> RAISED: $300</li>
                                    </ul>
                                    <h2>Single mothers.</h2>
                                    <p>Although progress has been made toward reducing child morbidity and mortality globally, a large proportion of children in sub-Saharan Africa still die before age five and many suffer chronic malnutrition. This study investigated the influence of single motherhood on stunting and under-5 mortality in Cameroon, Nigeria and Democratic Republic of the Congo (DRC). Particular attention was paid to the influence of mother’s economic resources, parental care and health behaviour on the difference in children’s health in single and two-parent families.
Methods
Data were obtained from most recent Demographic and Health Surveys in Cameroon (2011), Nigeria (2008) and DRC (2007). The sample included women aged 15–49 years old and their under-5 children 11,748 in Cameroon, 28,100 in Nigeria, and 8,999 in DRC. Logistic regression and Cox proportional hazard analysis were used to estimate stunting and under-5 mortality, respectively.
Results
The result showed that compared with children whose mothers were in union, children of single mothers who were not widows were more likely to be stunted. Economic resources and parental care significantly influenced the higher odds of stunting in single mother households in Cameroon and DRC. Relative to children of mothers in union, the risk of under-5 mortality in single mother families was higher in the three countries. Economic resources, parental care and health behaviour accounted for the difference in Nigeria and Cameroon; in DRC, only economic resources had marginal influence.
</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                             <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default CauseSection;