import React from 'react'
import {Link}  from 'react-router-dom'

import pmt3 from '../../images/checkout/img-3.png'
import pmt4 from '../../images/checkout/img-4.png'
import pmt5 from '../../images/checkout/bg-img.jpg'

import './style.css'

const Donate = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
    }

    return(
        <div className="tp-donation-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="tp-donate-header">
                            <h2>Make a Donation</h2>
                        </div>
                        <div id="Donations">
                            <div className="tp-doanation-payment">
                            <img src={pmt5} alt=""/>
                                <h2>Choose Your Payment Method</h2>
                                    <div className="tp-payment-area">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="tp-payment-option" id="open4">
                                                 
                                                    <div id="open5" className="payment-name">
                                                        <ul>
                                                            <li className="ski"><input id="3" type="radio" name="size" value="30"/>
                                                                <label htmlFor="3"><Link to={{ pathname: "https://www.paypal.com/donate?hosted_button_id=FU7R9R7ZM5NDS" }} target="_blank"><span><img src={pmt3} alt=""/></span></Link></label>
                                                            </li>
                                                            <li className="pay"><input id="4" type="radio" name="size" value="30"/>
                                                                <label htmlFor="4"><Link to={{ pathname: "https://dashboard.flutterwave.com/donate/uljnsbqlhzbf" }} target="_blank"><span><img src={pmt4} alt=""/></span></Link></label>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Donate;