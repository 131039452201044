import React from 'react'
import cause1 from '../../images/event/cause6.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const CauseSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
               
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={cause1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-clipboard"></i> GOAL: $20,000</li>
                                        <li><i className="ti-receipt"></i> RAISED: $/,000</li>
                                    </ul>
                                    <h2>Training and stills.</h2>
                                    <p>It is the kills that we equip them with that helps them in future has a source of income and being productive in the community to help others also </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title section-title2 text-center">
                             <Link onClick={ClickHandler} to="/donate" className="theme-btn">Donate Now<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default CauseSection;